
import { parseTime } from "@/utils/index.js";
import roles from "@/types/roles.ts";
import { ElMessageBox } from "element-plus";
import { useCityRelTown } from "@/network/chinaArea";
import Pager from "@/views/layout/pager.vue";
import StatusSubmit from "./statusSubmit.vue";
import Base from "@/views/layout/Base.vue";
import { useQuery, useFundWatchAction } from "@/network/fundWatch";
import { fundWatchDoStatusDic, fundWatchSourceDic } from "@/network/lookUp.ts";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  toRef,
  inject,
} from "vue";
import { openWindowWithToken, openPostWindow } from "@/utils/util";
export default defineComponent({
  components: {
    Base,
    StatusSubmit,
    Pager,
  },
  setup() {
    const user: any = inject("user");
    const setting = reactive({ visible: false, id: null, project_Name: null, active_Name: "fund" });
    const fundTotal = ref({
      project_Fund_Amount: 0,
      project_Fund_Amount_Used: 0,
      fundDoPercent: "",
    });
    const formAction = ref();
    const showEdit = (item) => {
      setting.visible = true;
      setting.id = item.project_Id;
      formAction.value = "edit";
    };

    const showView = (item) => {
      setting.id = item.project_Id;
      setting.visible = true;
      setting.active_Name = "fund";
      formAction.value = "view";
    };
    const yearDic = [];

    let startYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= 10; i++) {
      yearDic.push(startYear--);
    }

    const filter = reactive({
      primary: null,
      isMemo: null,
      year: null,
      source: "市级财政",
      keyword: null,
      city: null,
      town: null,
      pageNum: 1,
      pageSize: 20,
      status: null,
      sortBy: null,
      sortByDirection: null,
      isBelongProv: false,
    });

    if (user.inRoles(roles.fundCountyManager)) {
      filter.town = user.county_ID;
    } else if (user.inRoles(roles.city)) {
      filter.city = user.city_ID;
    }

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );
    const [isLoading, pagedList, _query, pager] = useQuery(filter);
    const query = (f = null) => {
      _query(f);
      queryFundTotal(filter).then((p) => {
        fundTotal.value = p;
      });
    };
    const [
      isProcessing,
      dispatch,
      memoUpdate,
      ,
      ,
      ,
      ,
      projectFind,
      queryFundTotal,
    ] = useFundWatchAction();
    onMounted(() => {
      query();
    });

    const exportExcel = () => {
      openPostWindow(
        `${process.env.VUE_APP_BASE_API}/FundWatch/export/query`,
        filter
      );
    };

    const showMemoEdit = (item) => {
      ElMessageBox.prompt("请填写备注内容", "备注", {
        inputValue: item.memo,
        type: "warning",
        customClass: "backConfirmForm",
        confirmButtonText: "保存",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPattern: /(.+){5,}/,
        inputErrorMessage: "内容不得少于5个字！",
        inputPlaceholder: "请输入备注内容",
      }).then(({ value }) => {
        item.memo = value;
        memoUpdate(item.id, value);
        //run(id, { forceFlag: 1, memo: value });
      });
    };

    return {
      formAction,
      parseTime,
      exportExcel,
      fundTotal,
      fundWatchDoStatusDic,
      fundWatchSourceDic,
      yearDic,
      dicCity,
      dicTown,
      filter,
      setting,
      showEdit,
      isLoading,
      pagedList,
      query,
      pager,
      user,
      roles,
      showView,
      showMemoEdit,
      isProcessing,
    };
  },
});
